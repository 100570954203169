<template>
  <base-material-card
    :icon="icon"
    class="v-card--material-stats"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-slot:after-heading>
      <div class="ml-auto text-right">
        <div
          class="grey--text font-weight"
          style="font-size:14px"
          v-text="title"
        />

        <h3 v-if="valuex === false" class="display-2 font-weight text--primary">
          {{ value }} <small>{{ smallValue }}</small>
        </h3>

        <div v-if="valuex === true" style="width: 200px; height: 40px;">
          <content-loader
            primaryColor="#d4ddf2"
            secondaryColor="#ffffff"
            :speed="1"
            :height="80"
          ></content-loader>
        </div>
      </div>
    </template>

    <v-col cols="12" class="px-0">
      <v-divider />
    </v-col>

    <div style="display: flex; align-items: center;">
      <v-icon :color="subIconColor" size="18" class="ml-2 mr-1">
        {{ subIcon }}
      </v-icon>
      <span
        style="font-size:12px"
        :class="subTextColor"
        class="font-weight"
        v-text="subText"
      />
      <v-btn
        v-if="showDownloadButtonOne"
        icon
        text
        small
        class="ml-2"
        color="blue"
        @click="$emit('download-click')"
      >
        <v-icon>mdi-download</v-icon>
      </v-btn>
    </div>

    <v-col v-if="subTextE !== undefined" cols="12" class="px-0">
      <v-divider />
    </v-col>

    <div v-if="subTextE !== undefined" style="display: flex; align-items: center;">
      <v-icon :color="subIconColorE" size="18" class="ml-2 mr-1">
        {{ subIconE }}
      </v-icon>
      <span
        style="font-size: 12px;"
        :class="subTextColor"
        class="font-weight"
        v-text="subTextE"
      />
      <v-btn
        v-if="showDownloadButtonTwo"
        icon
        text
        small
        class="ml-2"
        color="blue"
        @click="$emit('download-click-two')"
      >
        <v-icon>mdi-download</v-icon>
      </v-btn>
    </div>
  </base-material-card>
</template>

<script>
import Card from "./Card";
import { ContentLoader } from "vue-content-loader";

export default {
  name: "MaterialStatsCard",
  components: { ContentLoader },

  inheritAttrs: false,

  props: {
    ...Card.props,
    icon: {
      type: String,
      required: true,
    },
    subIcon: {
      type: String,
      default: undefined,
    },
    subIconColor: {
      type: String,
      default: undefined,
    },
    subIconE: {
      type: String,
      default: undefined,
    },
    subIconColorE: {
      type: String,
      default: undefined,
    },
    subTextColor: {
      type: String,
      default: undefined,
    },
    subText: {
      type: String,
      default: undefined,
    },
    subTextE: {
      type: String,
      default: undefined,
    },
    title: {
      type: String,
      default: undefined,
    },
    value: {
      type: String,
      default: undefined,
    },
    valuex: {
      type: Boolean,
      default: undefined,
    },
    smallValue: {
      type: String,
      default: undefined,
    },
    showDownloadButtonOne: {
      type: Boolean,
      default: false, // Controla a exibição do botão
    },

    showDownloadButtonTwo: {
      type: Boolean,
      default: false, // Controla a exibição do botão
    },
  },
};
</script>

<style lang="sass">
.v-card--material-stats
  display: flex
  flex-wrap: wrap
  position: relative

  > div:first-child
    justify-content: space-between

  .v-card
    border-radius: 4px
    flex: 0 1 auto

  .v-card__text
    display: inline-block
    flex: 1 0 calc(100% - 120px)
    position: absolute
    top: 0
    right: 0
    width: 100%

    .custom-sub-text 
      font-size: 12px

  .v-card__actions
    flex: 1 0 100%
</style>