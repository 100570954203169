<template>
  <base-material-card
    :icon="icon"
    class="v-card--material-stats"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-slot:after-heading>
      <div class="ml-auto text-right">
        <div
          class="grey--text font-weight" style="font-size:18px"
          v-text="title"
        />
      </div>
    </template>
   
            </br>
            <v-container>
                <span>
                  <v-icon size="24" class="ml-1 mr-2">{{ ico1 }}</v-icon>
                  {{titulo1}}
                </span><br/>
                <v-divider  style="margin-bottom: 8px;" />
               
                <span v-if="texto1 !== undefined"  
                style="font-size: 14px;"  
                :class="subTextColor" 
                class="font-weight" 
                v-text="texto1" />

                <span v-if="valor1 !== undefined"  
                style="font-size: 14px; font-weight: bold;"
                :class="subTextColor" 
                class="font-weight" 
                v-text="valor1" />
             
            </v-container>

            <v-expansion-panels v-model="panel" multiple elevation="1" style="margin-top: 4px;">
            <v-expansion-panel>
              <v-expansion-panel-header>+ Informações</v-expansion-panel-header>
              <v-expansion-panel-content>
                <div v-for="(item, index) in lista1" :key="index" style="margin-bottom: 8px;">
                  <div>
                    <strong>{{ item.tipo }}</strong> - {{ formatarMoeda(item.total) }}
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

        </br>
            <v-container>
              
                <span v-if="texto2 !== undefined"  
                style="font-size: 14px;"  
                :class="subTextColor" 
                class="font-weight" 
                v-text="texto2" />

                <span v-if="valor2 !== undefined"  
                style="font-size: 14px; font-weight: bold;"
                :class="subTextColor" 
                class="font-weight" 
                v-text="valor2" />
             
            </v-container>

            <v-expansion-panels v-model="panel2" multiple elevation="1" style="margin-top: 4px;">
            <v-expansion-panel>
              <v-expansion-panel-header>+ Informações</v-expansion-panel-header>
              <v-expansion-panel-content>
                <div v-for="(item, index) in lista1" :key="index" style="margin-bottom: 8px;">
                  <div>
                    <strong>{{ item.tipo }}</strong> - {{ (item.qtd) }}
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

        </br>
            <v-container>
              
                <span v-if="texto3 !== undefined"  
                style="font-size: 14px;"  
                :class="subTextColor" 
                class="font-weight" 
                v-text="texto3" />

                <span v-if="valor3 !== undefined"  
                style="font-size: 14px; font-weight: bold;"
                :class="subTextColor" 
                class="font-weight" 
                v-text="valor3" />
             
            </v-container>

            <v-expansion-panels v-model="panel3" multiple elevation="1" style="margin-top: 4px;">
            <v-expansion-panel>
              <v-expansion-panel-header>+ Informações</v-expansion-panel-header>
              <v-expansion-panel-content>
                <div v-for="(item, index) in lista1" :key="index" style="margin-bottom: 8px;">
                  <div>
                    <strong>{{ item.tipo }}</strong> - {{ formatarMoeda(item.media) }}
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>


        </br>
            <v-container>
              
                <span v-if="texto4 !== undefined"  
                style="font-size: 14px;"  
                :class="subTextColor" 
                class="font-weight" 
                v-text="texto4" />

                <span v-if="valor4 !== undefined"  
                style="font-size: 14px; font-weight: bold;"
                :class="subTextColor" 
                class="font-weight" 
                v-text="valor4" />
             
            </v-container>

            <v-expansion-panels v-model="panel4" multiple elevation="1" style="margin-top: 4px;">
            <v-expansion-panel>
              <v-expansion-panel-header>+ Informações</v-expansion-panel-header>
              <v-expansion-panel-content>
                <div v-for="(item, index) in lista1" :key="index" style="margin-bottom: 8px;">
                  <div>
                    <strong>{{ item.tipo }}</strong> - {{ (item.contratos) }}
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
            
  </base-material-card>
</template>

<script>
  import Card from './Card'
  import { ContentLoader } from "vue-content-loader"

 
  export default {
    name: 'MaterialStatsCardContas',
    components: { ContentLoader },
    inheritAttrs: false,
    data () {
      return {
        panel: [1], // Deixa o primeiro painel aberto
        panel2: [1], // Deixa o primeiro painel aberto
        panel3: [1], // Deixa o primeiro painel aberto
        panel4: [1], // Deixa o primeiro painel aberto

      }
    },

    props: {
      ...Card.props,
      downloadFunction: Function, 
   

      
      icon: {
        type: String,
        default: undefined,
      },

      title: {
        type: String,
        default: undefined,
      },
  
      ico1: {
        type: String,
        default: undefined,
      },
  
      titulo1: {
        type: String,
        default: undefined,
      },

      texto1: {
        type: String,
        default: undefined,
      },
      valor1: {
        type: String,
        default: undefined,
      },

      texto2: {
        type: String,
        default: undefined,
      },
      valor2: {
        type: String,
        default: undefined,
      },


      texto3: {
        type: String,
        default: undefined,
      },
      valor3: {
        type: String,
        default: undefined,
      },

      texto4: {
        type: String,
        default: undefined,
      },
      valor4: {
        type: String,
        default: undefined,
      },

      lista1: {
        type: Array,
        default: undefined,
      },

      subTextColor: {
        type: String,
        default: undefined,
      },
      
     
    },

    methods: {
      calculateHeight() {
      // Suponha que você queira 30% da altura do elemento pai
      const parentHeight = 120; // Substitua isso pela altura real do elemento pai
      const percentage = 30;
      return (parentHeight * percentage) / 100;
      },

      formatarMoeda(valor) {
        // Verifica se o valor é numérico
        if (isNaN(valor)) {
            return 'Valor inválido';
        }

        // Formata o valor como moeda brasileira (BRL)
        return valor.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        });
    },
    },
  }
</script>

<style scoped>
  .no-elevation {
    box-shadow: none !important;
  }
</style>

<style lang="sass">
.v-card--material-stats
  display: flex
  flex-wrap: wrap
  position: relative

  > div:first-child
    justify-content: space-between

  .v-card
    border-radius: 4px
    flex: 0 1 auto

  .v-card__text
    display: inline-block
    flex: 1 0 calc(100% - 120px)
    position: absolute
    top: 0
    right: 0
    width: 100%

    .custom-sub-text 
      font-size: 12px
    

  .v-card__actions
    flex: 1 0 100%
</style>
