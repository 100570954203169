<template>
  <base-material-card
    :icon="icon"
    class="v-card--material-stats"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-slot:after-heading>
      <div class="ml-auto text-right">
        <div
          class="grey--text font-weight" style="font-size:12px;font-weight: bold;"
          v-text="title"
        />
      </div>
    </template>
   
            </br>
            <v-container>
            <span>  <v-icon size="24" class="ml-1 mr-2"> mdi-cash </v-icon> 
              Informações de NF 
            </span ><br/>
            <v-divider  style="margin-bottom: 8px;" />
            <span v-if="subTextAdd !== undefined"  
            style="font-size: 14px;"  
            :class="subTextColor" 
            class="font-weight" 
            v-text="subTextAdd" />
            <span v-if="subTextAddV !== undefined"  
            style="font-size: 14px; font-weight: bold;"
            :class="subTextColor" 
            class="font-weight" 
            v-text="subTextAddV" />
          </br>

            <span v-if="subTextAdd1 !== undefined"  
            style="font-size: 14px;"  
            :class="subTextColor" 
            class="font-weight" 
            v-text="subTextAdd1" />
            <span v-if="subTextAdd1V !== undefined"  
            style="font-size: 14px; font-weight: bold;"
            :class="subTextColor" 
            class="font-weight" 
            v-text="subTextAdd1V" />


          </br>
          <br/>
            <span v-if="subTextAdd2 !== undefined"  
            style="font-size: 14px;"  
            :class="subTextColor" 
            class="font-weight" 
            v-text="subTextAdd2" />
            <span v-if="subTextAdd2V !== undefined"  
            style="font-size: 14px; font-weight: bold;"
            :class="subTextColor" 
            class="font-weight" 
            v-text="subTextAdd2V" />
          </br>
          <br/>
            <span v-if="subTextAdd3 !== undefined"  
            style="font-size: 14px;height: 100px;"  
            :class="subTextColor" 
            class="font-weight" 
            v-text="subTextAdd3" />
            <span v-if="subTextAdd3V !== undefined"  
            style="font-size: 14px;font-weight: bold; "
            :class="subTextColor" 
            class="font-weight" 
            v-text="subTextAdd3V"/>
            </br>
            

            <span v-if="subTextAdd4 !== undefined"  
            style="font-size: 14px;margin-top: 204px;"  
            :class="subTextColor" 
            class="font-weight" 
            v-text="subTextAdd4" />
            <span v-if="subTextAdd4V !== undefined"  
            style="font-size: 14px;font-weight: bold;"
            :class="subTextColor" 
            class="font-weight" 
            v-text="subTextAdd4V" /><br/>

            
            </v-container>

     

            
  </base-material-card>
</template>

<script>
  import Card from './Card'
  import { ContentLoader } from "vue-content-loader"

 
  export default {
    name: 'MaterialStatsCardNF',
    components: { ContentLoader },
    inheritAttrs: false,
    data () {
      return {
        panel: [1], // Deixa o primeiro painel aberto

      }
    },

    props: {
      ...Card.props,
      downloadFunction: Function, 
      icon: {
        type: String,
        required: true,
      },
      subIcon: {
        type: String,
        default: undefined,
      },
      subIconColor: {
        type: String,
        default: undefined,
      },
      subIconE: {
        type: String,
        default: undefined,
      },
      subIconColorE: {
        type: String,
        default: undefined,
      },
      subTextColor: {
        type: String,
        default: undefined,
      },
      subText: {
        type: String,
        default: undefined,
      },
      subTextE: {
        type: String,
        default: undefined,
      },

      subTextColorE: {
        type: String,
        default: undefined,
      },

      subTextAdd: {
        type: String,
        default: "",
      },
      subTextAdd1: {
        type: String,
        default: "",
      },
      subTextAdd2: {
        type: String,
        default: "",
      },
      subTextAdd3: {
        type: String,
        default: "",
      },
      subTextAdd4: {
        type: String,
        default: "",
      },
      subTextAdd5: {
        type: String,
        default: "",
      },
      subTextAdd6: {
        type: String,
        default: "",
      },
      subTextAdd7: {
        type: String,
        default: "",
      },
      subTextAdd8: {
        type: String,
        default: "",
      },

      subTextAdd9: {
        type: String,
        default: "",
      },

      subTextAdd10: {
        type: String,
        default: "",
      },

      subTextAdd11: {
        type: String,
        default: "",
      },

      subTextAdd12: {
        type: String,
        default: "",
      },

      subTextAdd13: {
        type: String,
        default: "",
      },

      subTextAddV: {
        type: String,
        default: "0",
      },
      subTextAdd1V: {
        type: String,
        default: "0",
      },
      subTextAdd2V: {
        type: String,
        default: "0",
      },
      subTextAdd3V: {
        type: String,
        default: "0",
      },
      subTextAdd4V: {
        type: String,
        default: undefined,
      },
      subTextAdd5V: {
        type: String,
        default: undefined,
      },
      subTextAdd6V: {
        type: String,
        default: undefined,
      },
      subTextAdd7V: {
        type: String,
        default: undefined,
      },

      subTextAdd8V: {
        type: String,
        default: undefined,
      },

      subTextAdd9V: {
        type: String,
        default: undefined,
      },

      subTextAdd10V: {
        type: String,
        default: undefined,
      },

      subTextAdd11V: {
        type: String,
        default: undefined,
      },

      subTextAdd12V: {
        type: String,
        default: undefined,
      },

      subTextAdd13V: {
        type: String,
        default: undefined,
      },


      subTextAdd2E: {
        type: String,
        default: undefined,
      },

      btn_download: {
        type: String,
        default: undefined,
      },
      
      subTextAdd3E: {
        type: String,
        default: undefined,
      },
      subTextAdd4E: {
        type: String,
        default: undefined,
      },

      title: {
        type: String,
        default: undefined,
      },
      value: {
        type: String,
        default: undefined,
      },
      valuex: {
        type: Boolean,
        default: undefined,
      },
     
      smallValue: {
        type: String,
        default: undefined,
      },
    },

    methods: {
      calculateHeight() {
      // Suponha que você queira 30% da altura do elemento pai
      const parentHeight = 120; // Substitua isso pela altura real do elemento pai
      const percentage = 30;
      return (parentHeight * percentage) / 100;
      }
    },
  }
</script>

<style scoped>
  .no-elevation {
    box-shadow: none !important;
  }
</style>

<style lang="sass">
.v-card--material-stats
  display: flex
  flex-wrap: wrap
  position: relative

  > div:first-child
    justify-content: space-between

  .v-card
    border-radius: 4px
    flex: 0 1 auto

  .v-card__text
    display: inline-block
    flex: 1 0 calc(100% - 120px)
    position: absolute
    top: 0
    right: 0
    width: 100%

    .custom-sub-text 
      font-size: 12px
    

  .v-card__actions
    flex: 1 0 100%
</style>
