import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "hash",
  //mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: "*",
      //component: NotFoundComponent,
      component: () => import("@/views/dashboard/Page404"),
      name: "NotFound",
    },

    {
      path: "/",
      component: () => import("@/views/dashboard/Index"),

      //component: () => import('@/views/dashboard/Login'),
      children: [
        // Dashboard

        {
          name: "Início",
          path: "inicio",
          component: () => import("@/views/dashboard/Inicio"),
        },

        {
          name: "Controle de Acesso",
          component: () => import("@/views/dashboard/Restrito"),
          path: "restrito",
        },

        {
          name: "Sepultamento",
          path: "sepultamento",
          component: () => import("@/views/dashboard/Sepultamento"),
        },

        {
          name: "Campanha",
          path: "campanha",
          component: () => import("@/views/dashboard/Campanha"),
        },

        {
          name: "Campanha2",
          path: "campanha2",
          component: () => import("@/views/dashboard/Campanha2"),
        },

        {
          name: "Historico",
          path: "historico",
          component: () => import("@/views/dashboard/Historico"),
        },

        {
          name: "Notificacao",
          path: "notificacao",
          component: () => import("@/views/dashboard/Notificacao"),
        },

        {
          name: "Register",
          path: "register",
          component: () => import("@/views/dashboard/Register"),
        },

        {
          name: "Faturamento",
          path: "faturamento",
          component: () => import("@/views/dashboard/Faturamento"),
        },

        {
          name: "Retenção de Vendas",
          path: "retencao",
          component: () => import("@/views/dashboard/RetencaoVendas"),
        },

        {
          name: "Acompanhamento Venda",
          path: "acompanhamentovenda",
          component: () => import("@/views/dashboard/AcompanhamentoVenda"),
        },

        {
          name: "Documentos",
          path: "documentos",
          component: () => import("@/views/dashboard/ConsultaDocumentos"),
        },

        {
          name: "Comissão de Acordo",
          path: "comissao",
          component: () => import("@/views/dashboard/Comissao"),
        },

        {
          name: "Funeraria",
          path: "funeraria",
          component: () => import("@/views/dashboard/Funeraria"),
        },

        {
          name: "Loja",
          path: "loja",
          component: () => import("@/views/dashboard/Loja"),
        },

        {
          name: "Comissão de Venda",
          path: "comissaoVenda",
          component: () => import("@/views/dashboard/ComissaoVenda"),
        },

        {
          name: "Comissão de Entrega",
          path: "comissaoEntrega",
          component: () => import("@/views/dashboard/ComissaoEntrega"),
        },

        {
          name: "Controle de Vínculo",
          path: "comissaoControle",
          component: () => import("@/views/dashboard/ComissaoControle"),
        },

        {
          name: "Ocorrência",
          path: "comissaoConformidade",
          component: () => import("@/views/dashboard/ComissaoNaoConformidade"),
        },

        {
          name: "Comissão Alelo",
          path: "comissaoAlelo",
          component: () => import("@/views/dashboard/ComissaoAlelo"),
        },

        {
          name: "Bitrix Lead",
          path: "bitrixLead",
          component: () => import("@/views/dashboard/BitrixLead"),
        },

        {
          name: "Comissão de Funeraria",
          path: "comissaoFuneraria",
          component: () => import("@/views/dashboard/ComissaoFuneraria"),
        },

        {
          name: "Contratos",
          path: "contrato",
          component: () => import("@/views/dashboard/Contrato"),
        },

        {
          name: "Cobrança",
          path: "cobranca",
          component: () => import("@/views/dashboard/Cobranca"),
        },

        {
          name: "Negativado",
          path: "negativados",
          component: () => import("@/views/dashboard/Negativados"),
        },

        {
          name: "Importação",
          path: "importacao",
          component: () => import("@/views/dashboard/Importacao"),
        },

        {
          name: "Indicadores",
          path: "indicadores",
          component: () => import("@/views/dashboard/Indicadores"),
        },

        {
          name: "Fluxo",
          path: "fluxo",
          component: () => import("@/views/dashboard/Fluxo"),
        },

        {
          name: "Importação APP",
          path: "importacaoapp",
          component: () => import("@/views/dashboard/Importacaoapp"),
        },

        {
          name: "Conciliação",
          path: "conciliacao",
          component: () => import("@/views/dashboard/Conciliacao"),
        },

        {
          name: "Remessa",
          path: "remessa",
          component: () => import("@/views/dashboard/Remessa"),
        },

        // Pages
        {
          name: "Usuário",
          path: "usuario",
          component: () => import("@/views/dashboard/Usuario"),
        },

        // Pages
        {
          name: "Auditoria",
          path: "auditoria",
          component: () => import("@/views/dashboard/Auditoria"),
        },

        // Pages
        {
          name: "Vendas",
          path: "venda",
          component: () => import("@/views/dashboard/Venda"),
        },

        {
          name: "Renegociações",
          path: "acordos",
          component: () => import("@/views/dashboard/Acordos"),
        },

        {
          name: "Vendas entre Empresas",
          path: "vendasEmpresas",
          component: () => import("@/views/dashboard/VendasEmpresas"),
        },

        {
          name: "Receitas",
          path: "receita",
          component: () => import("@/views/dashboard/Receita"),
        },

        {
          name: "Inadimplência",
          path: "inadimplencia",
          component: () => import("@/views/dashboard/Inadimplencia"),
        },

        {
          name: "Teste",
          path: "teste",
          component: () => import("@/views/dashboard/Teste"),
        },

        {
          name: "Cancelamento",
          path: "cancelamento",
          component: () => import("@/views/dashboard/Cancelamento"),
        },

        {
          name: "Óbitos",
          path: "obito",
          component: () => import("@/views/dashboard/Obito"),
        },

        {
          name: "Início",
          path: "inicio",
          component: () => import("@/views/dashboard/Inicio"),
        },

        {
          name: "Resumo",
          path: "financeiro",
          component: () => import("@/views/dashboard/Financeiro"),
        },

        {
          name: "Contas a Receber",
          path: "contas",
          component: () => import("@/views/dashboard/Contas"),
        },

        {
          name: "Inadimplência",
          path: "inadimplenciaPerformance",
          component: () => import("@/views/dashboard/InadimplenciaPerformance"),
        },

        {
          name: "Acordos",
          path: "acordosPerformance",
          component: () => import("@/views/dashboard/AcordosPerformance"),
        },

        {
          name: "Sinistralidade",
          path: "sinistralidade",
          component: () => import("@/views/dashboard/Sinistralidade"),
        },

        {
          name: "Cobranca",
          path: "mensageria-cobranca",
          component: () => import("@/views/dashboard/MensageriaCobranca"),
        },
        {
          name: "Lembrete",
          path: "mensageria-lembrete",
          component: () => import("@/views/dashboard/MensageriaLembrete"),
        },
        {
          name: "Consulta",
          path: "mensageria-consulta",
          component: () => import("@/views/dashboard/MensageriaConsulta"),
        },
      ],
    },
    {
      path: "/login",
      component: () => import("@/views/dashboard/Login"),
    },

    {
      path: "/restrito/atualizacao",
      component: () => import("@/views/restrito/Atualizacao"),
    },
  ],
});
